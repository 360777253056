import React from "react";
import { Button } from "primereact/button";
import styled from "styled-components";
import { sharedLabelStyles } from "./sharedLabelStyle";
import { calculateButtonStateColor } from "../../../../helpers/calculateButtonStateColor";
import { BrandColorProvider } from "../brand/BrandColorProvider";

export interface ButtonProps {
	colorHover: string;
	colorPress: string;
	color: string;
}

const ButtonPrimaryContainer = styled(Button)<ButtonProps>`
	display: inline-flex;
	padding: 10px 20px;
	justify-content: center;
	align-items: center;
	border-radius: 4px !important;
	background-color: ${(props) => props.color} !important;
	border-color: ${(props) => props.color} !important;
	.p-button-label {
		font-size: 16px;
		${sharedLabelStyles}
	}
	&:hover {
		background-color: ${(props) => props.colorHover} !important;
		border-color: ${(props) => props.colorHover} !important;
	}
	&:active {
		background-color: ${(props) => props.colorPress} !important;
		border-color: ${(props) => props.colorPress} !important;
	}
	&:disabled {
		opacity: 0.6;
	}
`;
export interface ButtonOwnProps {
	className?: any;
	label?: string;
	onClick?: (e?: any) => void;
	children?: any;
	disabled?: boolean;
	tooltipOptions?: any;
	tooltip?: string;
	type?: "button" | "reset" | "submit";
	icon?: any;
	loading?: boolean;
	autoFocus?: boolean;
	style?: any;
}

export const PrimaryButton = ({
	className,
	label,
	onClick,
	children,
	disabled,
	tooltipOptions,
	tooltip,
	type,
	icon,
	loading,
	autoFocus,
	style,
}: ButtonOwnProps) => {
	return (
		<BrandColorProvider>
			{(brandColor) => {
				const { hoverColor, pressColor } = calculateButtonStateColor(brandColor);
				return (
					<ButtonPrimaryContainer
						color={brandColor}
						tooltipOptions={tooltipOptions}
						disabled={disabled}
						colorHover={hoverColor}
						colorPress={pressColor}
						className={`p-button-primary bg-primary border-primary ${className}`}
						onClick={onClick}
						label={label}
						tooltip={tooltip}
						type={type}
						icon={icon}
						loading={loading}
						autoFocus={autoFocus}
						style={style}
					>
						{children}
					</ButtonPrimaryContainer>
				);
			}}
		</BrandColorProvider>
	);
};
