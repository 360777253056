import React from "react";
import { Button } from "primereact/button";
import styled from "styled-components";
import { ButtonOwnProps, ButtonProps } from "./PrimaryButton";
import { sharedLabelStyles } from "./sharedLabelStyle";
import {
	calculateButtonStateColor,
	wissenwirktBrandColor,
} from "../../../../helpers/calculateButtonStateColor";
import { BrandColorProvider } from "../brand/BrandColorProvider";

const SecondaryButtonContainer = styled(Button)<ButtonProps>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	border-radius: 4px !important;
	border: 1px solid ${(props) => props.color} !important;
	color: ${(props) => props.color} !important;

	.p-button-label {
		font-size: 16px;
		${sharedLabelStyles}
	}

	&:hover {
		background-color: rgba(94, 95, 98, 0.08) !important;
		border-color: ${(props) => props.colorHover} !important;
		color: ${(props) => props.colorHover} !important;
		backdrop-filter: blur(20px);
	}

	&:active {
		border-color: ${(props) => props.colorPress} !important;
		color: ${(props) => props.colorPress} !important;
		background: rgba(94, 95, 98, 0.16) !important;
		backdrop-filter: blur(20px);
	}

	&:disabled {
		opacity: 0.6;
	}
`;

export const SecondaryButton = ({
	className,
	label,
	onClick,
	children,
	disabled,
	tooltipOptions,
	tooltip,
	type,
	icon,
	loading,
	autoFocus,
}: ButtonOwnProps) => {
	return (
		<BrandColorProvider>
			{(brandColor) => {
				const { hoverColor, pressColor } = calculateButtonStateColor(brandColor);
				return (
					<SecondaryButtonContainer
						color={brandColor || wissenwirktBrandColor}
						tooltipOptions={tooltipOptions}
						disabled={disabled}
						colorHover={hoverColor}
						colorPress={pressColor}
						className={`p-button-outlined p-button-secondary bg-white ${className}`}
						onClick={onClick}
						label={label}
						tooltip={tooltip}
						type={type}
						icon={icon}
						loading={loading}
						autoFocus={autoFocus}
					>
						<div>{children}</div>
					</SecondaryButtonContainer>
				);
			}}
		</BrandColorProvider>
	);
};
