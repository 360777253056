import React from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { ValidatedField } from "../core/components/form/ValidatedField";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { NotLoggedInLoginForm_LoginMutation } from "../../../__generated__/NotLoggedInLoginForm_LoginMutation.graphql";
import { JwtTokenData, setLoggedIn } from "../../redux/slices/AuthSlice";
import { useTypedDispatch } from "../../../Store";
import { NotLoggedInLoginForm_BrandFragment$key } from "../../../__generated__/NotLoggedInLoginForm_BrandFragment.graphql";
import { redirectAfterAuthentication } from "../auth/utils/redirect-after-authentication";
import DocCheckIcon from "../../assets/infectopharm-doccheck-icon.png";
import styled from "styled-components";
import { PrimaryButton } from "../core/components/buttons/PrimaryButton";
import { SecondaryButton } from "../core/components/buttons/SecondaryButton";
import { TertiaryButton } from "../core/components/buttons/TertiaryButton";

const BRAND_FRAGMENT = graphql`
	fragment NotLoggedInLoginForm_BrandFragment on Brand {
		id
		name
		brandColorHex
		icon {
			url
		}
	}
`;

const LOGIN_MUTATION = graphql`
	mutation NotLoggedInLoginForm_LoginMutation($input: LoginJwtInput!) {
		Auth {
			loginJwt(input: $input) {
				jwtTokens {
					accessToken
					refreshToken
				}
			}
		}
	}
`;

interface FormState {
	email: string;
	password: string;
}

interface OwnProps {
	brandFragmentRef: NotLoggedInLoginForm_BrandFragment$key;
}

export const NotLoggedInLoginForm = ({ brandFragmentRef }: OwnProps) => {
	const [login, isLoggingIn] = useMutation<NotLoggedInLoginForm_LoginMutation>(LOGIN_MUTATION);
	const dispatch = useTypedDispatch();
	const navigate = useNavigate();

	const brand = useFragment<NotLoggedInLoginForm_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandFragmentRef || null,
	);

	const params = useParams<{ brandId: string; redirectBase64?: string }>();
	const brandId = params.brandId ?? process.env.REACT_APP_WISSENWIRKT_ID;

	const formik = useFormik<FormState>({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt."),
			password: Yup.string()
				.min(8, "Ein Passwort muss mindestens 8 Stellen lang sein.")
				.required("Das Feld Passwort wird benötigt."),
		}),
		onSubmit: (data) => {
			login({
				variables: {
					input: {
						email: data.email,
						password: data.password,
						base64BrandId: brandId!,
					},
				},
				onCompleted: (response) => {
					if (response.Auth.loginJwt) {
						dispatch(
							setLoggedIn({
								tokenData: response.Auth.loginJwt?.jwtTokens as JwtTokenData,
							}),
						);

						if (params.redirectBase64) {
							redirectAfterAuthentication(
								params.redirectBase64,
								response.Auth.loginJwt?.jwtTokens,
							);
						} else {
							navigate("/");
						}
					}
				},
				onError: () => {
					// toast.error("Login was unsuccessful. Please check your email and password.");
				},
			});
		},
	});

	const navigateToRegistration = (brandId: string) => {
		if (brandId) {
			return `/registration/${brandId}`;
		} else return `/registration/${process.env.REACT_APP_WISSENWIRKT_ID}`;
	};
	return (
		<div className="flex  align-items-center ">
			<div>
				<h1 className="mb-4">Jetzt einloggen</h1>
				<p className="text-sm mb-4">
					Bitte geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden{" "}
				</p>
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<FormState, string>
						name={"email"}
						label={"E-Mail"}
						iconClass={"pi-envelope"}
						formikConfig={formik}
						component={({ fieldValue, updateField, fieldName, isValid }) => {
							return (
								<InputText
									id={fieldName}
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									className={classNames({ "p-invalid": !isValid })}
								/>
							);
						}}
					/>
					<ValidatedField<FormState, string>
						name={"password"}
						label={"Passwort"}
						formikConfig={formik}
						component={({ fieldValue, updateField, fieldName, isValid }) => {
							return (
								<Password
									id={fieldName}
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									toggleMask
									feedback={false}
									className={classNames({ "p-invalid": !isValid })}
								/>
							);
						}}
					/>
					<PrimaryButton
						disabled={isLoggingIn}
						type="submit"
						label="Einloggen"
						className="mt-2 mb-2"
					/>

					<DocCheckButton
						type="button"
						icon={
							<img className="mr-2" height={30} alt="doccheck" src={DocCheckIcon} />
						}
						className="p-button-outlined p-button-secondary p-button"
						label="Einloggen mit DocCheck"
						onClick={() => navigate(`/doccheck/${brandId}/${params.redirectBase64}`)}
					/>
				</form>
				<TertiaryButton
					className="underline p-0 mt-4 mb-2"
					onClick={() => navigate(`/forgot-password/${brand.id}`)}
					label={"Passwort / Zugangsdaten vergessen"}
				/>
				<SecondaryButton
					label="Registrieren"
					className="bg-white mt-2 mb-2 w-full"
					onClick={() => navigate(navigateToRegistration(brand.id))}
				/>
			</div>
		</div>
	);
};

const DocCheckButton = styled(Button)`
	background-color: transparent !important;
	border-width: 1px !important;
	border-style: solid !important;
	:hover {
		background-color: transparent !important;
	}
`;
