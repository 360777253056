import { useFormik } from "formik";
import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import {
	ContactDetailsFormPart,
	ContactDetailsFormState,
} from "../roles/roleunlock/ContactDetailsFormPart";
import { AddressFormPart, AddressFormState } from "../roles/roleunlock/AddressFormPart";
import { useFragment, useMutation } from "react-relay";
import { EditRoleForm_EditRoleMutation } from "../../../__generated__/EditRoleForm_EditRoleMutation.graphql";
import { useDialogLogic } from "../core/components/dialog/useDialogLogic";
import { EditRoleForm_UserInformationFragment$key } from "../../../__generated__/EditRoleForm_UserInformationFragment.graphql";
import { PrimaryButton } from "../core/components/buttons/PrimaryButton";
import { SecondaryButton } from "../core/components/buttons/SecondaryButton";

const USER_INFORMATION_FRAGMENT = graphql`
	fragment EditRoleForm_UserInformationFragment on UserInformation {
		id
		roleApplicationProcess {
			address {
				company
				street
				postalCode
				city
				country
			}
			contactDetails {
				phoneNumber
				faxNumber
			}
		}
	}
`;

const EDIT_ROLE_MUTATION = graphql`
	mutation EditRoleForm_EditRoleMutation($input: EditRoleInput!) {
		Roles {
			applyForRoleChange(input: $input) {
				userStatus {
					...DashboardScreen_UserStatusFragment
				}
			}
		}
	}
`;

type FormState = ContactDetailsFormState & AddressFormState;

interface OwnProps {
	onClose: () => void;
	userInformationFragmentRef: EditRoleForm_UserInformationFragment$key;
}

export const EditRoleForm = ({ onClose, userInformationFragmentRef }: OwnProps) => {
	const userInformation = useFragment<EditRoleForm_UserInformationFragment$key>(
		USER_INFORMATION_FRAGMENT,
		userInformationFragmentRef,
	);

	const [editRole, isEditing] = useMutation<EditRoleForm_EditRoleMutation>(EDIT_ROLE_MUTATION);

	const { dialogComponent, showDialog } = useDialogLogic();

	const formik = useFormik<FormState>({
		initialValues: {
			company: userInformation.roleApplicationProcess?.address.company || "",
			country: userInformation.roleApplicationProcess?.address.country,
			city: userInformation.roleApplicationProcess?.address.city,
			postalCode: userInformation.roleApplicationProcess?.address.postalCode,
			street: userInformation.roleApplicationProcess?.address.street,
			phone: userInformation.roleApplicationProcess!.contactDetails.phoneNumber,
			fax: userInformation.roleApplicationProcess?.contactDetails.faxNumber || "",
		},
		onSubmit: (values) => {
			const addressChanged =
				userInformation.roleApplicationProcess?.address.company !== values.company ||
				userInformation.roleApplicationProcess?.address.street !== values.street ||
				userInformation.roleApplicationProcess?.address.postalCode !== values.postalCode ||
				userInformation.roleApplicationProcess?.address.city !== values.city ||
				userInformation.roleApplicationProcess?.address.country !== values.country;

			const contactDetailsChanged =
				userInformation.roleApplicationProcess?.contactDetails.phoneNumber !==
					values.phone ||
				userInformation.roleApplicationProcess?.contactDetails.faxNumber !== values.fax;

			const change = () => {
				editRole({
					variables: {
						input: {
							userId: userInformation.id,
							changedAddress: addressChanged
								? {
										company:
											values.company ||
											userInformation.roleApplicationProcess!.address.company,
										country:
											values.country ||
											userInformation.roleApplicationProcess!.address.country,
										city:
											values.city ||
											userInformation.roleApplicationProcess!.address.city,
										street:
											values.street ||
											userInformation.roleApplicationProcess!.address.street,
										postalCode:
											values.postalCode ||
											userInformation.roleApplicationProcess!.address
												.postalCode,
								  }
								: undefined,
							changedContactDetails: contactDetailsChanged
								? {
										phoneNumber:
											values.phone ||
											userInformation.roleApplicationProcess!.contactDetails
												.phoneNumber,
										faxNumber:
											values.fax ||
											userInformation.roleApplicationProcess!.contactDetails
												.faxNumber,
								  }
								: undefined,
						},
					},
					onCompleted: () => {
						onClose();
					},
				});
			};

			if (addressChanged) {
				showDialog({
					title: "Achtung: Diese Änderungen ziehen einen Prüfungsprozess nach sich",
					content:
						"Die Änderung der Adressdaten erfordert eine Neuprüfung Ihrer Freischaltung.",
					affirmativeText: "Speichern & Prüfen",
					negativeText: "Abbrechen",
					dialogCallback: (result) => {
						if (result === "Accept") {
							change();
						}
					},
				});
			} else if (contactDetailsChanged) {
				change();
			} else {
				onClose();
			}
		},
	});
	return (
		<>
			{dialogComponent}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ContactDetailsFormPart formik={formik} />
				<AddressFormPart
					formik={formik}
					userCurrentCountry={userInformation.roleApplicationProcess?.address.country}
				/>

				<PrimaryButton className="mb-2" disabled={isEditing} label={"Speichern"} />
				<SecondaryButton type={"reset"} label={"Abbrechen"} onClick={() => onClose()} />
			</form>
		</>
	);
};
