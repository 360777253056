import React from "react";
import { useParams } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { BrandColorProvider_Query } from "../../../../../__generated__/BrandColorProvider_Query.graphql";

const QUERY = graphql`
	query BrandColorProvider_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Brand {
				name
				id
				brandColorHex
			}
		}
	}
`;

interface BrandColorProviderProps {
	children: (brandColor: string) => React.ReactNode;
}

export const BrandColorProvider: React.FC<BrandColorProviderProps> = ({ children }) => {
	const { brandId } = useParams();
	const idBrand: string = brandId || process.env.REACT_APP_WISSENWIRKT_ID!;
	const data = useLazyLoadQuery<BrandColorProvider_Query>(QUERY, {
		id: idBrand,
		skip: !idBrand,
	});

	const brandColor = data.node?.brandColorHex || process.env.REACT_APP_DEFAULT_COLOR!;

	return <>{children(brandColor)}</>;
};
