import React from "react";
import styled from "styled-components";
import { BrandColorProps } from "../../../signup/SignUpProcessDialog";
import { Checkbox } from "primereact/checkbox";
import { calculateButtonStateColor } from "../../../../helpers/calculateButtonStateColor";
import { BrandColorProvider } from "../brand/BrandColorProvider";
interface OwnProps {
	className: any;
	inputId?: string;
	id?: string;
	onChange: (e: any) => void;
	checked: boolean;
	name?: string;
}
export const CustomedCheckbox = ({ className, inputId, onChange, checked, id, name }: OwnProps) => {
	return (
		<BrandColorProvider>
			{(brandColor) => {
				const { pressColor } = calculateButtonStateColor(brandColor);
				return (
					<StyledCheckbox
						brandColor={brandColor}
						colorPress={pressColor}
						className={className}
						inputId={inputId}
						id={id}
						name={name}
						onChange={onChange}
						checked={checked}
					/>
				);
			}}
		</BrandColorProvider>
	);
};
export const StyledCheckbox = styled(Checkbox)<BrandColorProps>`
	.p-checkbox-box.p-highlight {
		border-color: ${(props) => props.brandColor}!important;
		background-color: ${(props) => props.brandColor} !important;
	}
	.p-focus {
		border-color: ${(props) => props.colorPress} !important;
		background-color: ${(props) => props.colorPress} !important;
	}
`;
