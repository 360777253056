import { InfectopharmCountry } from "../../../../__generated__/MTARoleApplicationForm_ApplyRoleMutation.graphql";
import { InfectopharmRole } from "../../../../__generated__/PersonalAndProfessionalDataStep_BrandFragment.graphql";

export type OmittedInfectopharmRoles = Exclude<
	InfectopharmRole,
	"%future added value" | "Doccheck"
>;

export interface Speciality {
	label: string;
	value: string;
}

const specialitiesAvailableInGermany: Record<OmittedInfectopharmRoles, Speciality[]> = {
	Doctor: [
		{ label: "Allgemeinmedizin", value: "Allgemeinmedizin" },
		{ label: "Pathologie", value: "Pathologie" },
		{ label: "Anästhesiologie", value: "Anästhesiologie" },
		{
			label: "Innere Medizin / Gastroenterologie",
			value: "Innere Medizin / Gastroenterologie",
		},
		{ label: "Biochemie", value: "Biochemie" },
		{ label: "Innere Medizin / Kardiologie", value: "Innere Medizin / Kardiologie" },
		{ label: "Chirurgie / Viszeralchirurgie", value: "Chirurgie / Viszeralchirurgie" },
		{ label: "Chirurgie / Gefäßchirurgie", value: "Chirurgie / Gefäßchirurgie" },
		{ label: "Chirurgie", value: "Chirurgie" },
		{ label: "Chirurgie / Kinderchirurgie", value: "Chirurgie / Kinderchirurgie" },
		{ label: "Chirurgie / Thoraxchirurgie", value: "Chirurgie / Thoraxchirurgie" },
		{
			label: "Chirurgie / Plastische und Ästhetische Chirurgie",
			value: "Chirurgie / Plastische und Ästhetische Chirurgie",
		},
		{ label: "Haut- und Geschlechtskrankheiten", value: "Haut- und Geschlechtskrankheiten" },
		{
			label: "Innere Medizin / Endokrinologie und Diabetologie",
			value: "Innere Medizin / Endokrinologie und Diabetologie",
		},
		{ label: "Laboratoriumsmedizin", value: "Laboratoriumsmedizin" },
		{ label: "Physiologie", value: "Physiologie" },
		{
			label: "Frauenheilkunde und Geburtshilfe / Gynäkologische Onkologie",
			value: "Frauenheilkunde und Geburtshilfe / Gynäkologische Onkologie",
		},
		{
			label: "Innere Medizin / Hämatologie und Internistische Onkologie",
			value: "Innere Medizin / Hämatologie und Internistische Onkologie",
		},
		{ label: "Innere Medizin / Angiologie", value: "Innere Medizin / Angiologie" },
		{ label: "Innere Medizin", value: "Innere Medizin" },
		{ label: "Rechtsmedizin", value: "Rechtsmedizin" },
		{ label: "Nuklearmedizin", value: "Nuklearmedizin" },
		{ label: "Arbeitsmedizin", value: "Arbeitsmedizin" },
		{
			label: "Mikrobiologie, Virologie und Infektionsepidemiologie",
			value: "Mikrobiologie, Virologie und Infektionsepidemiologie",
		},
		{ label: "Innere Medizin / Nephrologie", value: "Innere Medizin / Nephrologie" },
		{ label: "Innere Medizin / Pneumologie", value: "Innere Medizin / Pneumologie" },
		{ label: "Neurochirurgie", value: "Neurochirurgie" },
		{ label: "Neurologie", value: "Neurologie" },
		{ label: "Frauenheilkunde und Geburtshilfe", value: "Frauenheilkunde und Geburtshilfe" },
		{ label: "Augenheilkunde", value: "Augenheilkunde" },
		{ label: "Hals-Nasen-Ohrenheilkunde", value: "Hals-Nasen-Ohrenheilkunde" },
		{ label: "Kinder- und Jugendmedizin", value: "Kinder- und Jugendmedizin" },
		{ label: "Psychiatrie und Psychotherapie", value: "Psychiatrie und Psychotherapie" },
		{ label: "Strahlentherapie", value: "Strahlentherapie" },
		{
			label: "Physikalische und Rehabilitative Medizin",
			value: "Physikalische und Rehabilitative Medizin",
		},
		{ label: "Innere Medizin / Rheumatologie", value: "Innere Medizin / Rheumatologie" },
		{ label: "Innere und Allgemeinmedizin", value: "Innere und Allgemeinmedizin" },
		{ label: "Urologie", value: "Urologie" },
		{ label: "Orthopädie und Unfallchirurgie", value: "Orthopädie und Unfallchirurgie" },
		{ label: "Chirurgie / Unfallchirurgie", value: "Chirurgie / Unfallchirurgie" },
		{ label: "Chirurgie / Herzchirurgie", value: "Chirurgie / Herzchirurgie" },
		{
			label: "Kinder- und Jugendmedizin / Neonatologie",
			value: "Kinder- und Jugendmedizin / Neonatologie",
		},
		{
			label: "Kinder- und Jugendmedizin / Neuropädiatrie",
			value: "Kinder- und Jugendmedizin / Neuropädiatrie",
		},
		{ label: "Kinder- und Jugendpsychiatrie", value: "Kinder- und Jugendpsychiatrie" },
		{ label: "Klinische Pharmakologie", value: "Klinische Pharmakologie" },
		{ label: "Radiologie", value: "Radiologie" },
		{ label: "Öffentliches Gesundheitswesen", value: "Öffentliches Gesundheitswesen" },
		{
			label: "Kinder- und Jugendmedizin / Kinderkardiologie",
			value: "Kinder- und Jugendmedizin / Kinderkardiologie",
		},
		{ label: "Neuropathologie", value: "Neuropathologie" },
		{ label: "Radiologie / Neuroradiologie", value: "Radiologie / Neuroradiologie" },
		{
			label: "Chirurgie / Mund-Kiefer-Gesichtschirurgie",
			value: "Chirurgie / Mund-Kiefer-Gesichtschirurgie",
		},
		{ label: "Psychotherapeutische Medizin", value: "Psychotherapeutische Medizin" },
		{ label: "Humangenetik", value: "Humangenetik" },
		{ label: "Hygiene und Umweltmedizin", value: "Hygiene und Umweltmedizin" },
		{ label: "Sonstiges", value: "Sonstiges" },
		{ label: "Transfusionsmedizin", value: "Transfusionsmedizin" },
		{
			label: "Frauenheilkunde und Geburtshilfe / Gynäkologische Endokrinologie und Reproduktionsmedizin",
			value: "Frauenheilkunde und Geburtshilfe / Gynäkologische Endokrinologie und Reproduktionsmedizin",
		},
		{
			label: "Praktischer Arzt / Praktische Ärztin",
			value: "Praktischer Arzt / Praktische Ärztin",
		},
		{
			label: "Psychiatrie und Psychotherapie / Forensische Psychiatrie",
			value: "Psychiatrie und Psychotherapie / Forensische Psychiatrie",
		},
		{ label: "Psychotherapie**", value: "Psychotherapie**" },
		{
			label: "Psychosomatische Medizin und Psychotherapie",
			value: "Psychosomatische Medizin und Psychotherapie",
		},
		{ label: "Phoniatrie und Pädaudiologie", value: "Phoniatrie und Pädaudiologie" },
		{
			label: "Frauenheilkunde und Geburtshilfe / Spezielle Geburtshilfe und Perinatalmedizin",
			value: "Frauenheilkunde und Geburtshilfe / Spezielle Geburtshilfe und Perinatalmedizin",
		},
		{ label: "Orthopädie / Rheumatologie", value: "Orthopädie / Rheumatologie" },
		{
			label: "Kinder- und Jugendmedizin / Hämatologische und Pädiatrische Onkologie",
			value: "Kinder- und Jugendmedizin / Hämatologische und Pädiatrische Onkologie",
		},
		{ label: "Radiologie / Kinderradiologie", value: "Radiologie / Kinderradiologie" },
		{ label: "Pharmakologie und Toxikologie", value: "Pharmakologie und Toxikologie" },
		{ label: "Orthopädie", value: "Orthopädie" },
	],
	Apothecary: [
		{
			label: "Krankenhaus-Apotheke / Krankenhaus-Apotheker*in",
			value: "Krankenhaus-Apotheke/-Apotheker",
		},
		{ label: "Apotheken / Apotheker*in", value: "Apotheken/Apotheker" },
	],
	Midwife: [],
	PTA: [],
	PKA: [],
	MTA: [],
	PharmaceuticalEngineer: [],
	Other: [],
	Unknown: [],
};

const specialitiesAvailableInAustria: Record<OmittedInfectopharmRoles, Speciality[]> = {
	Doctor: [
		{ label: "Allgemein Medizin", value: "Allgemein Medizin" },
		{ label: "Pathologie", value: "Pathologie" },
		{
			label: "Anästhesiologie und Intensivmedizin",
			value: "Anästhesiologie und Intensivmedizin",
		},
		{ label: "Chirurgie", value: "Chirurgie" },
		{ label: "Kinderchirurgie", value: "Kinderchirurgie" },
		{ label: "Thoraxchirurgie", value: "Thoraxchirurgie" },
		{ label: "Plastische Chirurgie", value: "Plastische Chirurgie" },
		{ label: "Dermatologie", value: "Dermatologie" },
		{ label: "Labormedizin", value: "Labormedizin" },
		{ label: "Innere Medizin", value: "Innere Medizin" },
		{ label: "Neurologie und Psychiatrie", value: "Neurologie und Psychiatrie" },
		{ label: "Nuklearmedizin", value: "Nuklearmedizin" },
		{ label: "Arbeits- und Betriebsmedizin", value: "Arbeits- und Betriebsmedizin" },
		{ label: "Lungenkrankheiten", value: "Lungenkrankheiten" },
		{ label: "Neurochirurgie", value: "Neurochirurgie" },
		{ label: "Psychiatrie und Neurologie", value: "Psychiatrie und Neurologie" },
		{ label: "Neurologie", value: "Neurologie" },
		{ label: "Frauenheilkunde und Geburtshilfe", value: "Frauenheilkunde und Geburtshilfe" },
		{ label: "Augenheilkunde und Optometrie", value: "Augenheilkunde und Optometrie" },
		{ label: "HNO", value: "HNO" },
		{ label: "Kinder- und Jugendheilkunde", value: "Kinder- und Jugendheilkunde" },
		{ label: "Psychiatrie", value: "Psychiatrie" },
		{ label: "Radiologie", value: "Radiologie" },
		{ label: "Strahlentherapie", value: "Strahlentherapie" },
		{
			label: "Physikalische und rehabilitative Medizin",
			value: "Physikalische und rehabilitative Medizin",
		},
		{ label: "Orthopädie und Traumatologie", value: "Orthopädie und Traumatologie" },
		{ label: "Urologie", value: "Urologie" },
		{
			label: "Orthopädie und orthopädische Chirurgie",
			value: "Orthopädie und orthopädische Chirurgie",
		},
		{ label: "Tropenmedizin", value: "Tropenmedizin" },
		{ label: "Unfallchirurgie", value: "Unfallchirurgie" },
		{ label: "Neuropädiatrie", value: "Neuropädiatrie" },
		{ label: "Kinder- und Jugendpsychiatrie", value: "Kinder- und Jugendpsychiatrie" },
		{
			label: "Kinder- und Jugendpsychiatrie und psychotherapeutische Medizin",
			value: "Kinder- und Jugendpsychiatrie und psychotherapeutische Medizin",
		},
		{
			label: "Kinder- und Jugendneuropsychiatrie",
			value: "Kinder- und Jugendneuropsychiatrie",
		},
		{ label: "Innere Medizin -  Infektiologie", value: "Innere Medizin -  Infektiologie" },
		{ label: "Zahn-/Mund-/Kieferheilkunde", value: "Zahn-/Mund-/Kieferheilkunde" },
		{
			label: "Psychiatrie und Psychotherapeutische Medizin",
			value: "Psychiatrie und Psychotherapeutische Medizin",
		},
		{ label: "In Ausbildung", value: "In Ausbildung" },
		{ label: "Hygiene und Mikrobiologie", value: "Hygiene und Mikrobiologie" },
		{ label: "Klinische Immunologie", value: "Klinische Immunologie" },
		{
			label: "Blutgruppendiagnostik und Transfusionsmedizin",
			value: "Blutgruppendiagnostik und Transfusionsmedizin",
		},
		{ label: "Anatomie", value: "Anatomie" },
	],
	Apothecary: [
		{ label: "Pharmazie KH", value: "Pharmazie KH" },
		{ label: "Pharmakologie und Toxikologie", value: "Pharmakologie und Toxikologie" },
		{ label: "Klinische Pharmakologie", value: "Klinische Pharmakologie" },
		{ label: "Pharmazie", value: "Pharmazie" },
	],
	Midwife: [],
	PTA: [],
	PKA: [],
	MTA: [],
	PharmaceuticalEngineer: [],
	Other: [],
	Unknown: [],
};

export const getAvailableSpecialitiesList = (
	country: InfectopharmCountry,
	targetRole: OmittedInfectopharmRoles,
): Speciality[] => {
	switch (country) {
		case "Deutschland":
			return specialitiesAvailableInGermany[targetRole];
		case "Oesterreich":
			return specialitiesAvailableInAustria[targetRole];
		default:
			return [];
	}
};
