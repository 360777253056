import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { DeleteMyselfButton_DeleteMutation } from "../../../__generated__/DeleteMyselfButton_DeleteMutation.graphql";
import { useDialogLogic } from "../core/components/dialog/useDialogLogic";
import { DeleteMyselfButton_UserFragment$key } from "../../../__generated__/DeleteMyselfButton_UserFragment.graphql";
import { logout } from "../../redux/slices/AuthSlice";
import { useTypedDispatch } from "../../../Store";
import { PrimaryButton } from "../core/components/buttons/PrimaryButton";

const USER_FRAGMENT = graphql`
	fragment DeleteMyselfButton_UserFragment on UserInformation {
		isLocked
	}
`;

const DELETE_MUTATION = graphql`
	mutation DeleteMyselfButton_DeleteMutation($input: DeleteMyselfInput!) {
		InfectopharmAuth {
			deleteMyself(input: $input) {
				clientMutationId
			}
		}
	}
`;

interface OwnProps {
	userFragmentRef: DeleteMyselfButton_UserFragment$key;
	className?: string;
	brandId: string;
}

export const DeleteMyselfButton = ({ userFragmentRef, className, brandId }: OwnProps) => {
	const user = useFragment<DeleteMyselfButton_UserFragment$key>(USER_FRAGMENT, userFragmentRef);
	const [startDelete, isInFlight] =
		useMutation<DeleteMyselfButton_DeleteMutation>(DELETE_MUTATION);

	const { dialogComponent, showDialog } = useDialogLogic();
	const dispatch = useTypedDispatch();

	return (
		<>
			{dialogComponent}
			<PrimaryButton
				className={"p-button-danger " + (className ? className : "")}
				disabled={isInFlight || user.isLocked}
				onClick={() => {
					showDialog({
						title: "Mein Konto löschen?",
						content:
							"Wenn du dein Konto löschst, kannst du dich nicht mehr einloggen. Bitte beachten Sie, dass durch die Löschung Ihres Nutzerkontos all Ihre Zugänge zu Infectopharm und Pädia Websites / Lernplattformen gelöscht werden! Das Löschen kann nicht rückgängig gemacht werden. ",
						affirmativeText: "Ja, mein Konto löschen.",
						negativeText: "Nein",
						dialogCallback: (result) => {
							if (result === "Accept") {
								startDelete({
									variables: { input: { brandId: brandId } },
									onCompleted: () => {
										toast.success("Konto erfolgreich gelöscht.");
										dispatch(logout());
									},
								});
							}
						},
					});
				}}
			>
				Mein Konto löschen
			</PrimaryButton>
		</>
	);
};
