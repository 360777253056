import React from "react";
import { Button } from "primereact/button";
import styled from "styled-components";
import { ButtonOwnProps, ButtonProps } from "./PrimaryButton";
import { sharedLabelStyles } from "./sharedLabelStyle";
import {
	calculateButtonStateColor,
	wissenwirktBrandColor,
} from "../../../../helpers/calculateButtonStateColor";
import { BrandColorProvider } from "../brand/BrandColorProvider";

const TertiaryButtonContainer = styled(Button)<ButtonProps>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px !important;
	color: ${(props) => props.color} !important;
	.p-button-label {
		font-size: 14px;
		${sharedLabelStyles}
	}
	&:hover {
		color: ${(props) => props.colorHover} !important;
		backdrop-filter: blur(20px);
	}
	&:active {
		color: ${(props) => props.colorPress} !important;
		backdrop-filter: blur(20px);
	}
	&:disabled {
		opacity: 0.6;
	}
`;

export const TertiaryButton = ({
	className,
	label,
	onClick,
	children,
	disabled,
	tooltipOptions,
	tooltip,
	type,
	icon,
	loading,
	autoFocus,
	style,
}: ButtonOwnProps) => {
	return (
		<BrandColorProvider>
			{(brandColor) => {
				const { hoverColor, pressColor } = calculateButtonStateColor(brandColor);
				return (
					<TertiaryButtonContainer
						color={brandColor || wissenwirktBrandColor}
						tooltipOptions={tooltipOptions}
						disabled={disabled}
						colorHover={hoverColor}
						colorPress={pressColor}
						className={`text-primary bg-white ${className}`}
						onClick={onClick}
						label={label}
						tooltip={tooltip}
						type={type}
						icon={icon}
						loading={loading}
						autoFocus={autoFocus}
						style={style}
					>
						<div>{children}</div>
					</TertiaryButtonContainer>
				);
			}}
		</BrandColorProvider>
	);
};
