import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { ChangePasswordForm_ChangePasswordMutation } from "../../../__generated__/ChangePasswordForm_ChangePasswordMutation.graphql";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../core/components/form/ValidatedField";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { ChangePasswordForm_UserFragment$key } from "../../../__generated__/ChangePasswordForm_UserFragment.graphql";
import { PrimaryButton } from "../core/components/buttons/PrimaryButton";

const USER_FRAGMENT = graphql`
	fragment ChangePasswordForm_UserFragment on UserInformation {
		isLocked
	}
`;

const CHANGE_PASSWORD_MUTATION = graphql`
	mutation ChangePasswordForm_ChangePasswordMutation($input: ChangePasswordInput!) {
		InfectopharmAuth {
			changePassword(input: $input) {
				clientMutationId
			}
		}
	}
`;

interface FormState {
	password: string;
	password2: string;
}

interface OwnProps {
	userFragmentRef: ChangePasswordForm_UserFragment$key;
}

export const ChangePasswordForm = ({ userFragmentRef }: OwnProps) => {
	const user = useFragment<ChangePasswordForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef);
	const [changePassword, isChanging] =
		useMutation<ChangePasswordForm_ChangePasswordMutation>(CHANGE_PASSWORD_MUTATION);
	const formik = useFormik<FormState>({
		initialValues: {
			password: "",
			password2: "",
		},
		validationSchema: Yup.object().shape({
			password: Yup.string()
				.min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
				.required("Das Feld Passwort wird benötigt"),
			password2: Yup.string().oneOf(
				[Yup.ref("password"), undefined],
				"Passwörter müssen übereinstimmen.",
			),
		}),
		onSubmit: (values) => {
			changePassword({
				variables: {
					input: {
						value: values.password,
					},
				},
				onCompleted: () => {
					toast("Passwort erfolgreich geändert.", { type: "success" });
					formik.resetForm();
				},
			});
		},
	});
	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"password"}
				label={"Neues Passwort"}
				formikConfig={formik}
				iconClass={"pi-lock"}
				disabled={user.isLocked}
				component={({ fieldValue, updateField, fieldName, isValid, disabled }) => {
					return (
						<Password
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							toggleMask={false}
							feedback={true}
							weakLabel={"Schwach"}
							mediumLabel={"Mittel"}
							strongLabel={"Stark"}
							disabled={disabled}
							placeholder={"Neues Passwort eingeben..."}
							onChange={(e) => updateField(e.target.value)}
							className={classNames({ "p-invalid": !isValid })}
						/>
					);
				}}
			/>
			<ValidatedField<FormState, string>
				name={"password2"}
				label={"Passwort (wiederholen)"}
				iconClass={"pi-lock"}
				required={true}
				disabled={user.isLocked}
				formikConfig={formik}
				component={({ fieldValue, updateField, fieldName, isValid, disabled }) => {
					return (
						<Password
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							toggleMask={false}
							feedback={false}
							disabled={disabled}
							autoComplete={"new-password"}
							placeholder={"Passwort wiederholen..."}
							onChange={(e) => updateField(e.target.value)}
							className={classNames({ "p-invalid": !isValid })}
						/>
					);
				}}
			/>
			<PrimaryButton disabled={isChanging || user.isLocked} label={"Speichern"} />
		</form>
	);
};
